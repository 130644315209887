import { mainStylesSearchRecipe, mainDivSearchRecipe, innerDivSearchRecipe, titleSpanSearchRecipe, noResultsDiv } from './busqueda-receta.module.css'
import React, { useEffect } from "react"
import DishCard from "../components/HomeDishes/DishCard"
import Footer from "../components/Footer"
import NavBar from "../components/NavBar"
import CategoriesBar from "../components/CategoriesBar/CategoriesBar"
import { useStaticQuery, graphql } from 'gatsby'
import * as queryString from "query-string";
import { getImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

const IndexPage = ({location}) => {
  const data = useStaticQuery(graphql`
  {
    allMysqlAllRecipes {
      nodes {
        image {
          childImageSharp {
            gatsbyImageData(width: 450, placeholder: BLURRED)
          }
        }
        name
        introText
        category
        pageUrl
        rate
      }
    }
  }
  `)

  const { search } = queryString.parse(location.search);
  const [ recipesFound, setRecipesFound ] = React.useState([]);

  useEffect(() => {
    var recipesFoundLocal = [];

    if (search.trim().length < 2) {
      setRecipesFound(recipesFoundLocal);
      return;
    }

    const recipesList = data.allMysqlAllRecipes.nodes;
    for (var i = 0; i < recipesList.length; i++) {
      var currentRecipe = recipesList[i];
      var recipeName = currentRecipe.name.toLowerCase();

      if (recipeName.toLowerCase().includes(search.toLowerCase())) {
        recipesFoundLocal.push(currentRecipe);
      }
    }

    setRecipesFound(recipesFoundLocal);
  }, [search, location.search])

  return (
    <div className={mainStylesSearchRecipe}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Las recetas más fáciles y ricas para que prepares en tu casa - Fede Cocina</title>
        <meta name="description" content="Las recetas más fáciles y ricas para que prepares en tu casa. No te pierdas ninguna!"></meta>
        <html lang="es"/>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
      </Helmet>
      <NavBar updateOnKeyPress={true} showFixedSearch={true}></NavBar>
      <CategoriesBar></CategoriesBar>
      <div className={mainDivSearchRecipe}>
        { recipesFound.length > 0 ?
          <>
            <span className={titleSpanSearchRecipe}>Las mejores recetas de {search}</span>
            <div className={innerDivSearchRecipe}>
              { recipesFound.map((item) => {

                const image = getImage(item.image.childImageSharp);
                return (
                  <DishCard key={item.id} pageUrl={item.pageUrl} image={image} name={item.name} shortText={item.introText} category={item.category} rate={item.rate} slimMargin={false}></DishCard>
                )
              })
              }
            </div>
          </>
          :
          <div className={noResultsDiv}>
            No hubo resultados para tu busqueda, proba hacer otra!
          </div>
        }
      </div>
      <Footer></Footer>
    </div>
  )
}

export default IndexPage
